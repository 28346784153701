import { yupResolver } from "@hookform/resolvers/yup";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  where,
  writeBatch,
  type Timestamp,
} from "firebase/firestore";
import { debounce, sortBy } from "lodash";
import moment from "moment-timezone";
import type { Availability } from "practicare/types/availability.model";
import type { Customer } from "practicare/types/customer.model";
import type { Location } from "practicare/types/location.model";
import type { Room } from "practicare/types/room.model";
import type { UserServiceVariant } from "practicare/types/service.model";
import type { User } from "practicare/types/user.model";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { renderSelectField } from "src/components/forms/renderSelectField";
import {
  APPOINTMENT_REPEAT_OPTIONS,
  APPOINTMENT_TYPES,
  getNestedProperty,
} from "src/config/utils";
import type { AppointmentAddModalData } from "src/context/appointmentAddModal";
import { useAuth } from "src/context/auth";
import UILoader from "src/layout/components/ui-loader";
import { type AppState } from "src/redux/store";
import * as yup from "yup";
import { addDocWithUser, db } from "../../config/firebase";
import { renderCheckboxField } from "../forms/renderCheckbox";
import { renderDatePicker } from "../forms/renderDatePicker";
import { renderInputField } from "../forms/renderInputField";
import { renderPhoneNumber } from "../forms/renderPhoneNumber";
import {
  UserServiceVariantOption,
  UserServiceVariantOptionSelected,
} from "../forms/utils";
import { type AppointmentAddModalAdminAppointmentFormData } from "./appointmentAddModalAdminCalendarLocation.types";

export const AppointmentAddModalAdminCustomizable = ({
  isOpen,
  closeModal,
  options,
}: {
  isOpen: boolean;
  closeModal: () => void;
  options: AppointmentAddModalData | undefined | null;
}) => {
  const { t, i18n } = useTranslation();
  const { user, isAdmin } = useAuth();
  const userData = user.userData;
  const storeRooms = useSelector((state: AppState) => state.rooms);
  const storeUsers = useSelector((state: AppState) => state.users);
  const storeLocations = useSelector((state: AppState) => state.locations);
  const [userServiceVariants, setUserServiceVariants] = useState<
    UserServiceVariant[]
  >([]);
  const [userAvailability, setUserAvailability] = useState<Availability[]>([]);
  const [userDataSet, setUserDataSet] = useState<string>("");
  const [userCustomers, setUserCustomers] = useState<Customer[]>([]);
  const [roomsSelectList, setRoomsSelectList] = useState<Room[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(
      "dateTime",
      options?.defaultDate ? moment(options.defaultDate).toDate() : null
    );
    setValue(
      "theraphist",
      storeUsers.data.find((u) => u.id === options?.defaultUserId) || null
    );
  }, [options]);

  const formSchema = yup.object().shape({
    isAddingNewCustomer: yup.boolean().required(t("Required field")),
    dateTime: yup.date().required(t("Required field")),
    customer: yup.object().when("isAddingNewCustomer", {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema.typeError(t("Required field")).required(t("Required field")),
    }),
    theraphist: yup
      .object()
      .typeError(t("Required field"))
      .required(t("Required field")),
    appointmentType: yup.object().nullable(),
    variant: yup
      .object()
      .typeError(t("Required field"))
      .required(t("Required field")),
    room: yup
      .object()
      .typeError(t("Required field"))
      .required(t("Required field")),
    location: yup
      .object()
      .typeError(t("Required field"))
      .required(t("Required field")),
    repeatType: yup.object().nullable(),
    repeats: yup
      .number()
      .integer()
      .min(0)
      .when("repeatType", {
        is: (r: any) => r !== null,
        then: (schema) => schema.required(t("Required field")),
        otherwise: (schema) => schema.nullable(),
      }),
    newCustomer: yup.object().when("isAddingNewCustomer", {
      is: true,
      then: (schema) =>
        schema.shape({
          firstName: yup.string().required(t("Required field")),
          lastName: yup.string().required(t("Required field")),
          phoneNumber: yup
            .string()
            .required(t("Required field"))
            .test(
              "is-valid-phone-number",
              t("Phone number is invalid"),
              (value) => (value ? isValidPhoneNumber(value) : false)
            ),
          email: yup
            .string()
            .email(t("Invalid Email"))
            .required(t("Invalid Email")),
        }),
      otherwise: (schema) => schema.nullable(),
    }),
    automaticCancelIfNotPaid: yup.boolean().required(t("Required field")),
    automaticCancelIfNotPaidHours: yup.number(),
    automaticCancelDate: yup.date(),
  });

  const defaultValues: AppointmentAddModalAdminAppointmentFormData = {
    dateTime: null,
    customer: null,
    theraphist: null,
    appointmentType: null,
    variant: null,
    room: null,
    location: null,
    repeatType: null,
    newCustomer: null,
    isAddingNewCustomer: false,
    repeats: 0,
    automaticCancelIfNotPaid: false,
    automaticCancelIfNotPaidHours: 24,
    automaticCancelDate: moment().add(24, "hours").toDate(),
  };

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(formSchema) as any,
  });

  const close = () => {
    reset();
    closeModal();
  };

  const createNewCustomerData = (
    appointDetails: AppointmentAddModalAdminAppointmentFormData
  ): Customer => ({
    firstName: appointDetails?.newCustomer?.firstName || "",
    lastName: appointDetails?.newCustomer?.lastName || "",
    phoneNumber: appointDetails?.newCustomer?.phoneNumber || "",
    email: appointDetails?.newCustomer?.email || "",
    comment: "",
    skype: "",
    source: null,
    contractSignDate: null,
    cancelledAppointments: [],
    assignedUsers: [
      {
        id: appointDetails.theraphist?.id || "",
        firstName: appointDetails.theraphist?.firstName || "",
        lastName: appointDetails.theraphist?.lastName || "",
        email: appointDetails.theraphist?.email || "",
        phoneNumber: appointDetails.theraphist?.phoneNumber || "",
      },
    ],
    assignedUsersIdMap: {
      [appointDetails?.theraphist?.id || ""]: true,
    },
    isDeleted: false,
    notificationsSettings: [
      {
        title: t("SMS Reminder"),
        type: "SMS_REMINDER",
        checked: { sms: true },
        disabled: { email: true },
      },
      {
        type: "EMAIL_FIRST_APPOINTMENT",
        title: t("First Appointment Confirmation"),
        checked: { email: true },
        disabled: { sms: false },
      },
      {
        title: t("Payment Link"),
        type: "EMAIL_PAYMENT",
        checked: { email: true },
        disabled: { sms: true },
      },
      {
        title: t("Payment Confirmation"),
        type: "EMAIL_PAYMENT_CONFIRMATION_CUSTOMER",
        checked: { email: true },
        disabled: { sms: true },
      },
      {
        title: t("Online appointment invitation"),
        type: "EMAIL_MEETING_INVITE",
        checked: { email: true },
        disabled: { sms: true },
      },
      {
        title: t("Coming soon - reminder about late appointments"),
        type: "LATE_PAYMENTS_REMINDER",
        checked: {},
        disabled: { sms: true, email: true },
      },
      {
        title: t("Coming soon - invoices"),
        type: "AUTOMATIC_INVOICES",
        checked: {},
        disabled: { sms: true, email: true },
      },
    ],
  });

  const createAppointmentData = (
    appointDetails: AppointmentAddModalAdminAppointmentFormData,
    parentId?: string,
    newDate?: Date
  ) => ({
    ...appointDetails,
    customer: {
      id: appointDetails.customer?.id,
      firstName: appointDetails.customer?.firstName,
      lastName: appointDetails.customer?.lastName,
      email: appointDetails.customer?.email,
      phoneNumber: appointDetails.customer?.phoneNumber,
      contractSignDate: appointDetails.customer?.contractSignDate || null,
      cancelledAppointments:
        appointDetails.customer?.cancelledAppointments || [],
    },
    theraphist: {
      id: appointDetails.theraphist?.id,
      firstName: appointDetails.theraphist?.firstName,
      lastName: appointDetails.theraphist?.lastName,
      avatar: appointDetails.theraphist?.avatar || "",
      email: appointDetails.theraphist?.email,
      phoneNumber: appointDetails.theraphist?.phoneNumber,
      isHiddenFromCalendar: !!appointDetails.theraphist?.isHiddenFromCalendar,
    },
    room: {
      id: appointDetails.room?.id,
      name: appointDetails.room?.name,
    },
    location: {
      id: appointDetails.location?.id,
      name: appointDetails.location?.name,
    },
    dateTime: newDate || appointDetails.dateTime,
    isDeleted: false,
    appointmentStatus: "SCHEDULED",
    paymentType: "ONLINE",
    paymentStatus: "PENDING",
    isNotificationProcessed:
      appointDetails.appointmentType?.key === "FIRST_APPOINTMENT"
        ? moment(appointDetails.dateTime).diff(moment(), "hours") < 72
        : false,
    createdBy: {
      id: userData?.id,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
    },
    createdAt: serverTimestamp(),
    parentId: parentId || null,
  });

  const bouncer = useCallback(
    debounce(async () => {
      setLoading(true);
      try {
        const appointDetails = watch();
        if (appointDetails.isAddingNewCustomer) {
          const newCustomerData = createNewCustomerData(appointDetails);
          const newCustomer = await addDocWithUser(
            collection(db, "customers"),
            newCustomerData,
            user
          );
          appointDetails.customer = { id: newCustomer.id, ...newCustomerData };
          delete appointDetails.newCustomer;
        }

        const batch = writeBatch(db);
        const appointmentData = createAppointmentData(appointDetails);
        let parentId = null;
        const docRef = await addDocWithUser(
          collection(db, "appointments"),
          appointmentData,
          user
        );
        parentId = docRef.id;

        if (appointDetails.repeats > 1 && appointDetails.repeatType) {
          for (let i = 1; i < appointDetails.repeats; i++) {
            const newDate = moment(appointDetails.dateTime)
              .add(
                appointDetails.repeatType.label === "Dwutygodniowe" ? i * 2 : i,
                appointDetails.repeatType.moment
              )
              .toDate();
            const repeatedAppointmentData = createAppointmentData(
              appointDetails,
              parentId || "",
              newDate
            );
            batch.set(doc(collection(db, "appointments")), {
              ...repeatedAppointmentData,
              appointmentType: null,
            });
          }
        }

        await batch.commit();
      } catch (e) {
        console.error(e);
        await addDocWithUser(
          collection(db, "errors"),
          { error: JSON.stringify(e), appointDetails: JSON.stringify(watch()) },
          user
        );
      } finally {
        setLoading(false);
        close();
      }
    }, 500),
    [watch, user, close]
  );

  const onSubmit = () => {
    setLoading(true);
    bouncer();
    return true;
  };

  const updateTheraphist = (theraphist: User) => {
    setValue("newCustomer", null);
    if (theraphist.id === "new") {
      setValue("isAddingNewCustomer", true);
      setValue("variant", null);
    } else {
      setValue("isAddingNewCustomer", false);
      setValue("customer", null);
      setValue("variant", null);
    }
  };

  const theraphist = watch("theraphist");

  useEffect(() => {
    const fetchUserData = async (userId: string) => {
      if (!userId) {
        return;
      }
      let customers: Customer[] = [];
      const variants: UserServiceVariant[] = [];
      const availability: Availability[] = [];
      try {
        const customersRef = query(
          collection(db, "customers"),
          where(`assignedUsersIdMap.${userId}`, "==", true)
        );
        const customersResponse = await getDocs(customersRef);
        customersResponse.forEach((e) => {
          if (!e.data().isDeleted) {
            customers.push({ ...(e.data() as Customer), id: e.id });
          }
        });

        customers = sortBy(customers, (o) => o.lastName);

        const servicesRef = query(
          collection(db, "userServicesVariants"),
          where("userId", "==", userId),
          where("isDeleted", "!=", true)
        );
        const services = await getDocs(servicesRef);
        services.forEach((e) =>
          variants.push({ id: e.id, ...(e.data() as UserServiceVariant) })
        );
        variants.sort((a, b) => {
          if (
            getNestedProperty(a, "variant.isOnline") === true &&
            getNestedProperty(b, "variant.isOnline") === false
          ) {
            return 1;
          }
          if (
            getNestedProperty(a, "variant.isOnline") === false &&
            getNestedProperty(b, "variant.isOnline") === true
          ) {
            return -1;
          }

          if (window.location.href.indexOf("osrodek") > -1) {
            if (a.price < b.price) {
              return 1;
            }
            if (a.price > b.price) {
              return 1;
            }
          }

          if (
            getNestedProperty(a, "variant.name") <
            getNestedProperty(b, "variant.name")
          ) {
            return 1;
          }
          if (
            getNestedProperty(a, "variant.name") >
            getNestedProperty(b, "variant.name")
          ) {
            return -1;
          }

          return 0; // if all comparisons are equal
        });
        const availabilityRef = query(
          collection(db, "availability"),
          where("theraphist.id", "==", userId),
          where("isDeleted", "==", false)
        );
        const userAvailability = await getDocs(availabilityRef);
        userAvailability.forEach((e) => {
          availability.push({ ...(e.data() as Availability), id: e.id });
        });
        setUserAvailability(availability);
        setUserServiceVariants(variants);
        setUserCustomers(customers);
        setUserDataSet(moment().format("YYYY-MM-DD HH:mm:ss"));
      } catch (e) {
        console.error(e);
      }
    };
    if (theraphist) {
      fetchUserData(theraphist.id);
    } else {
      setUserAvailability([]);
      setUserServiceVariants([]);
      setUserCustomers([]);
      setUserDataSet(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [theraphist]);

  const getCustomersList = (c: Customer[]) => {
    const newCustomerArray = [...c];
    newCustomerArray.unshift({
      id: "new",
      firstName: "Klient ---",
      lastName: "--- Nowy",
    } as any);
    return newCustomerArray;
  };

  const updateRooms = (location: Location, noClear?: boolean) => {
    const filteredRooms = storeRooms.data.filter(
      (r) => r.location.id === location.id
    );
    setRoomsSelectList(filteredRooms);
    if (noClear) {
      return true;
    }
    setValue("room", null);
    return true;
  };

  useEffect(() => {
    if (options?.suggestLocationAndRoom) {
      const currentDateTimeMoment = moment(watch("dateTime"));
      const eventDayOfWeek = currentDateTimeMoment.isoWeekday();
      const eventHour = currentDateTimeMoment.hours();
      const userFilteredAvailability = userAvailability.filter((a) => {
        const availStartDate = moment((a.startDate as Timestamp).toDate());
        const availEndDate = moment((a.endDate as Timestamp).toDate());
        if (
          a.type?.value === "BLOCK" ||
          a.type?.value === "TIME_OFF" ||
          availEndDate
            .endOf("day")
            .isSameOrBefore(currentDateTimeMoment.startOf("day"))
        ) {
          return false;
        }
        if (
          !(
            currentDateTimeMoment.isSameOrBefore(availEndDate.add(1, "d")) &&
            currentDateTimeMoment.isSameOrAfter(availStartDate.subtract(1, "d"))
          )
        ) {
          return false;
        }
        const availabilityStartHour = a.startHour
          ? moment((a.startHour as Timestamp).toDate()).hour()
          : 0;
        const availabilityEndHour = a.endHour
          ? moment((a.endHour as Timestamp).toDate()).hour()
          : 0;

        const isSameWeekDay = Number(a.dayOfWeek?.value) === eventDayOfWeek;
        const isWithinTimeSlot =
          availabilityStartHour <= eventHour &&
          eventHour <= availabilityEndHour;
        return isSameWeekDay && isWithinTimeSlot;
      });
      if (userFilteredAvailability.length > 0) {
        const { room, location } = userFilteredAvailability[0];
        setValue("room", room as Room);
        setValue("location", location as Location);
        updateRooms(location as Location, true);
      }
    }
  }, [userDataSet]);

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => close()}
        className="modal-dialog-centered"
      >
        <UILoader blocking={loading}>
          <ModalHeader
            className="bg-transparent justify-content-end"
            toggle={() => close()}
          ></ModalHeader>
          <ModalBody className="px-sm-5 mx-50 pb-5">
            <Row
              tag="form"
              className="gy-1 gx-2 mt-75"
              onSubmit={handleSubmit(onSubmit)}
            >
              {renderSelectField({
                name: "theraphist",
                placeholder: t("Select"),
                label: t("Employee"),
                isMulti: false,
                control: control,
                errors: errors,
                options: storeUsers.data.filter(
                  (u) => u.status === "ACTIVE" || u.status === "TERMINATION"
                ),
                onChangeFnc: (e) => updateTheraphist(e),
                styles: {
                  hideMargin: true,
                },
              })}

              {renderDatePicker({
                name: "dateTime",
                required: true,
                label: t("Date"),
                control: control,
                errors: errors,
                watch: watch,
                setValue: setValue,
                placeholder: t("Select date"),
                dateFormat: "Y-m-d H:i",
                enableTime: true,
                styles: {
                  hideMargin: true,
                  width50: true,
                },
                i18n,
              })}

              {renderSelectField({
                name: "customer",
                placeholder: t("Select"),
                label: t("Customer"),
                isMulti: false,
                control: control,
                required: true,
                errors: errors,
                options: getCustomersList(userCustomers),
                onChangeFnc: (e) => updateTheraphist(e),
                styles: {
                  hideMargin: true,
                  width50: true,
                },
              })}

              {watch("isAddingNewCustomer") && (
                <>
                  {renderInputField(
                    "newCustomer.firstName",
                    t("First Name"),
                    true,
                    control,
                    errors,
                    "text",
                    undefined,
                    undefined,
                    { hideMargin: true, width50: true }
                  )}
                  {renderInputField(
                    "newCustomer.lastName",
                    t("Last Name"),
                    true,
                    control,
                    errors,
                    "text",
                    undefined,
                    undefined,
                    { hideMargin: true, width50: true }
                  )}
                  {renderPhoneNumber(
                    "newCustomer.phoneNumber",
                    t("Phone Number"),
                    true,
                    control,
                    errors,
                    false,
                    { hideMargin: true, width50: true }
                  )}
                  {renderInputField(
                    "newCustomer.email",
                    t("Email"),
                    true,
                    control,
                    errors,
                    "email",
                    undefined,
                    undefined,
                    { hideMargin: true, width50: true }
                  )}
                </>
              )}

              {isAdmin &&
                renderSelectField({
                  name: "appointmentType",
                  label: t("Appointment Type"),
                  placeholder: t("Select"),
                  isMulti: false,
                  isClearable: true,
                  control: control,
                  errors: errors,
                  options: APPOINTMENT_TYPES,
                  getOptionLabelFnc: (option) => option.value,
                  getOptionValueFnc: (option) => option.key,
                  styles: { hideMargin: true },
                })}

              {renderSelectField({
                name: "variant",
                placeholder: t("Select"),
                label: t("Service Variant"),
                isMulti: false,
                required: true,
                control: control,
                errors: errors,
                options: userServiceVariants,
                disabled: !!(userServiceVariants.length === 0),
                getOptionLabelFnc: (option: UserServiceVariant) =>
                  option.variant.name,
                getOptionValueFnc: (option: UserServiceVariant) =>
                  option.id || "",
                components: {
                  Option: UserServiceVariantOption,
                  SingleValue: UserServiceVariantOptionSelected,
                },
                styles: { hideMargin: true },
              })}

              {renderSelectField({
                name: "location",
                placeholder: t("Select"),
                label: t("Location"),
                isMulti: false,
                required: true,
                control: control,
                errors: errors,
                options: storeLocations.data,
                disabled: false,
                onChangeFnc: (e) => updateRooms(e),
                getOptionLabelFnc: (option) => option.name,
                getOptionValueFnc: (option) => option.id,
                styles: { hideMargin: true, width50: true },
              })}

              {renderSelectField({
                name: "room",
                label: t("Room"),
                placeholder: t("Select"),
                isMulti: false,
                required: true,
                control: control,
                errors: errors,
                options: roomsSelectList,
                disabled: roomsSelectList.length === 0,
                getOptionLabelFnc: (option) => option.name,
                getOptionValueFnc: (option) => option.id,
                styles: { hideMargin: true, width50: true },
              })}

              {watch("appointmentType")?.key !== "RESERVATION" &&
                renderSelectField({
                  name: "repeatType",
                  label: t("Repeat Type"),
                  placeholder: t("Select"),
                  isMulti: false,
                  required: false,
                  control: control,
                  errors: errors,
                  options: APPOINTMENT_REPEAT_OPTIONS,
                  disabled: false,
                  getOptionLabelFnc: (option) => option.label,
                  getOptionValueFnc: (option) => option.value,
                  styles: { hideMargin: true, width50: true },
                })}

              {watch("appointmentType")?.key !== "RESERVATION" &&
                renderInputField(
                  "repeats",
                  t("Repeat Count"),
                  false,
                  control,
                  errors,
                  "number",
                  undefined,
                  watch("repeatType") === null,
                  { hideMargin: true, width50: true }
                )}

              {renderCheckboxField(
                "automaticCancelIfNotPaid",
                t("Delete if not paid"),
                control,
                errors
              )}

              {watch("automaticCancelIfNotPaid") && (
                <>
                  {renderInputField(
                    "automaticCancelIfNotPaidHours",
                    t("Time to pay in hours"),
                    false,
                    control,
                    errors,
                    "number",
                    (e, field) => {
                      field.onChange(e);
                      setValue(
                        "automaticCancelDate",
                        moment()
                          .add((e as any)?.target?.value, "hours")
                          .toDate()
                      );
                    },
                    undefined,
                    { hideMargin: true }
                  )}
                  <FormText>
                    {t("Automatic cancellation date")}:{" "}
                    {watch("automaticCancelDate")
                      ? moment(watch("automaticCancelDate")).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : null}
                  </FormText>
                </>
              )}

              <Col className="text-center mt-1" xs={12}>
                <Button type="submit" className="me-1" color="primary">
                  {t("Save")}
                </Button>

                <Button
                  color="secondary"
                  className="me-1"
                  outline
                  onClick={() => {
                    close();
                  }}
                >
                  {t("Cancel")}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </UILoader>
      </Modal>
    </Fragment>
  );
};
