import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment-timezone";
import { Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { initReactI18next } from "react-i18next";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.scss";
import de from "./config/locales/de.json";
import en from "./config/locales/en.json";
import es from "./config/locales/es.json";
import fr from "./config/locales/fr.json";
import pl from "./config/locales/pl.json";
import { AppointmentAddModalProvider } from "./context/appointmentAddModal";
import { useTenant } from "./context/tenant";
import SpinnerComponent from "./layout/components/spinner/Fallback-spinner";
import { calendarSlice } from "./redux/reducers/calendar";
import Router from "./router";
import "./scss/core.scss";
import "./scss/react/apps/app-users.scss";
import "./scss/react/libs/flatpickr/flatpickr.scss";
import "./scss/react/libs/react-select/_react-select.scss";
import "./scss/react/libs/tables/react-dataTable-component.scss";
import "./scss/react/pages/modal-create-app.scss";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "pl",
    fallbackLng: "pl",
    debug: false,
    keySeparator: false,
    resources: {
      pl: {
        translation: pl,
      },
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    load: "all",
  });
export let calendarWorker: Worker | null;
if (window.Worker) {
  calendarWorker = new Worker(`${window.location.origin}/calendarWorker.js`);
}

moment.tz.setDefault("Europe/Warsaw");
let isWorkerInit = false;
const App = () => {
  const { tenantSettings } = useTenant();
  const dispatch = useDispatch();
  if (window.Worker && calendarWorker && !isWorkerInit) {
    isWorkerInit = true;
    calendarWorker.addEventListener("message", (event) => {
      dispatch(calendarSlice.actions.setCalendarData(event.data));
    });
    calendarWorker.addEventListener("error", function (error) {
      console.error("Error in Web Worker:", error);
    });
  }
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState("");
  useEffect(() => {
    if (previousPath !== location.pathname) {
      setPreviousPath(location.pathname);
      if (!tenantSettings?.gaId) {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
      }
    }
  }, [location.pathname, previousPath, tenantSettings.loading]);

  return tenantSettings.loading ? (
    <SpinnerComponent />
  ) : (
    <Suspense fallback={null}>
      <AppointmentAddModalProvider>
        <Router />
      </AppointmentAddModalProvider>
    </Suspense>
  );
};

export default App;
